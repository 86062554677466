import {VALIDATE_API_KEY} from "./constants/apiKey";

export const validateAccount = async (uid) => {
    function _getValidateUrl() {
        const prefix = isDev() ? "http://localhost:5000/" : "https://anonchatapi.stivistoru.org/";
        return `${prefix}validate_uid`;
    }
    const urlParams = new URLSearchParams({
        uid,
        api_key: VALIDATE_API_KEY,
    });
    const res = await fetch(_getValidateUrl() + `?${urlParams.toString()}`);
    if (res.status === 200) {
        const responseJson = await res.json();
        return responseJson.success;
    } else {
        return false;
    }
}

export function isDev() {
    return process.env?.NODE_ENV === "development";
}

export function getApiUrl() {
    const prefix = isDev() ? "http://localhost:8788/" : "";
    return `${prefix}api/generate-payment-link`;
}
